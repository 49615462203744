import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { init } from '@sentry/browser';

// Global CSS
import '@try/styles/index.css';

const container = document.getElementById('root')!;
const root = ReactDOM.createRoot(container);

const EB2 = lazy(() => import('@try/eb2'));

const {
  VITE_SENTRY_DSN: dsn,
  VITE_GIT_COMMIT_SHA: release,
  VITE_ENVIRONMENT_LABEL: environment,
  VITE_ENABLE_SENTRY_LOCAL_CAPTURE,
} = import.meta.env;

// leaving this here for helpful debug purposes; could come in handy from time
// to time.
// @ts-expect-error - allowing access to release sha for debugging
window.__RELEASE = release;
init({
  dsn,
  release,
  environment,
  enabled:
    environment !== 'Local' || VITE_ENABLE_SENTRY_LOCAL_CAPTURE === 'true',
});

(() => {
  root.render(
    // TODO: Add ErrorBoundary back in
    // <ErrorBoundary fallback={ErrorAlert}>
    <Suspense fallback={null}>
      <EB2 />
    </Suspense>,
    // </ErrorBoundary>,
  );
})();
